<section class="content">
    <div class="container-fluid pt-2">
        <div class="custom-table-design headingTop">


            <h2 class="heading-font">Content Manager</h2>

            <ng-container   *ngFor="let group of groupList">
                <div class="manageBox"  *ngIf="group?.group_name != 'Watch History' &&  group?.group_name != 'Favorites'">
               
                  <div class="row">
                      <div class="col-10">
                          <h5 class="subHeading">{{group?.group_name}} </h5>
                      </div>
                      <div class="col-2 d-flex justify-content-end">
                          <div class="plusDv">
                              <a href="javascript:void(0)" data-bs-toggle="modal"  (click)="addVideoModal(group)" data-bs-target="#addVideoModal"> <img
                                      src="../../../assets/img/plus.png" alt=""></a>
                          </div>
                      </div>
                  </div>
                  <div class="row">
                  

                    <ng-container   *ngFor="let listItem of contentGroupList">
                       
                        <ng-container *ngIf="listItem?.content_group_id == group?.id">
                            <div class="col-md-6" *ngFor="let video of listItem.content">
                                <div class="dataDv">
                                    <div class="row align-items-center">
                                        <div class="col-9">
                                         
                                           
                                            <h3>{{video.heading}}</h3>
                                            <p>{{video.sub_heading}} </p>
                                        </div>
                                        <div class="col-3 dataAction d-flex justify-content-end">
                                            <a href="javascript:void(0)" (click)="editVideoModal(video, listItem.content_group_name)"   data-bs-toggle="modal" data-bs-target="#editVideoModal"
                                                class="edit">
                                                <i class="fas fa-pen mr-3"></i>
                                            </a>
                                            <a href="javascript:void(0)" (click)="confirmContentDeleteBox(video.id)" class="delete">
                                                <i class="fas fa-trash"></i>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        
                        </ng-container>
               
                  </ng-container>











                      
  
                  </div>
              </div>
          </ng-container>

 

        </div>
    </div>
</section>


<!-- ********** add Video Modal ********* -->

<!-- Modal -->
<div class="modal fade" id="addVideoModal" data-bs-backdrop="static" tabindex="-1" aria-hidden="true"  data-keyboard="false">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content" *ngIf="progress===null">
            <div class="modal-header" >
                <h5 class="modal-title" id="exampleModalLabel1">Add Video</h5>
                <button type="button" id="addVideoModalClose" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
           
            <div class="modal-body"  >
                <form name="addForm" [formGroup]="videoForm">
                    <div class="row">
                        <div class="col-md-5">
                            <div class='file customFile' >
                                <label class="text-center" for='file'   >

                                    <ng-container *ngIf="videoFileName; else noVideofile" >
                                        <div  class="p-3">
                                  
                                      
                                            <b>Your Video File</b><br>
                                            {{videoFileName}}
                                            
                                        </div>
                                       
                                    </ng-container>
                                    <ng-template #noVideofile>

                                        <img src="../../../assets/img/uploadFile.png" alt="">
                                        <span>Upload Video</span>
                                         
                                    </ng-template>






                                
                                </label>
                                <input id='file' type='file'   #file  accept='video/mp4,video/MOV,video/AVI '  (change)="onFileChange($event)" />
                                <div  *ngIf="!fileErrorMessage" class="invalid-tooltip d-block" style="right: 0;
                                font-size: 12px;
                                text-align: center;
                                color: #000;
                                background: none;
                                margin-top: -35px;">
                                   The maximum file upload size is 200 MB
                                </div>
                                <div *ngIf="fileErrorMessage" class="invalid-tooltip d-block" style="right: 0;
                                font-size: 12px;
                                text-align: center;
                                margin-top: -52px;">
                                    Exceeds the maximum file upload limit of 200MB
                                </div>
                            </div>
                           
                        </div>
                        <div class="col-md-7">
                            <div class="form-group  position-relative">
                                <label for="Title" class="col-form-label"> Title
                                    <!-- <span class="required-fields">*</span> -->
                                </label>
                                <input type="text" class="form-control"  formControlName="heading" placeholder="Video Title">
                                <div *ngIf="videoForm.controls['heading'].invalid && (videoForm.controls['heading'].dirty || videoForm.controls['heading'].touched)" class="invalid-tooltip d-block" style="right: 0;left: inherit;">
                                    <div *ngIf="videoForm.controls['heading'].hasError('required')">Please Enter Title</div>
                                    <div *ngIf="videoForm.controls['heading'].hasError('maxlength')">Maximum 50 characters allowed</div>
                                    
                                </div>
                            </div>
                            <div class="form-group position-relative">
                                <label for="Title" class="col-form-label"> Category
                                    <!-- <span class="required-fields">*</span> -->
                                </label>
                                <select class="form-control noborder form-select" disabled >
                                    <option>{{group_name}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="form-group position-relative"   >
                                <label for="Title" class="col-form-label"> Description
                                    <!-- <span class="required-fields">*</span> -->
                                </label>
                                <textarea rows="" cols="" class="form-control" formControlName="sub_heading">Description</textarea>
                                <div *ngIf="videoForm.controls['sub_heading'].invalid && (videoForm.controls['sub_heading'].dirty || videoForm.controls['sub_heading'].touched)" class="invalid-tooltip d-block" style="right: 0;left: inherit;">
                                    <div *ngIf="videoForm.controls['sub_heading'].hasError('required')">Please Enter Description</div>
                                    <div *ngIf="videoForm.controls['sub_heading'].hasError('maxlength')">Maximum 100 characters allowed</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>

            <div class="modal-footer" >
                <button type="button" class="btn btn-primary mt-3"  [disabled]="!videoForm.valid" (click)="addVideo(group_id)">Add Video</button>
                  
              </div>

        </div>
        <button style="display: none;" type="button" #addVideoModalClose class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        <div  *ngIf="progress!==null" style="margin: 0px auto;">
            <circle-progress
            [percent]="progress"
            [outerStrokeColor]="'#78C000'"
            [innerStrokeColor]="'#C7E596'"
            [animation]="true"
            [animationDuration]="0"
            [titleFontSize]="'28'"
            [titleFontWeight]="'900'"
            [unitsFontSize]="'28'"
            [unitsFontWeight]="'900'"
            [subtitle]="'Progress'"
            [subtitleFontSize]="'12'"
            [showBackground]="true"               
            [backgroundColor] = "'#ffffff'"
            [responsive]="false"
            [radius]="60"
            [space]="10"
          >
          </circle-progress>
        </div>
    </div>
</div>
<!-- ********** add Video Modal end********* -->

<!-- ********** edit Video Modal ********* -->
<!-- Modal -->
<div class="modal fade" id="editVideoModal" data-bs-backdrop="static" tabindex="-1" aria-hidden="true"  data-keyboard="false">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content" *ngIf="progress===null">
            <div class="modal-header"  >
                <h5 class="modal-title" id="exampleModalLabel1">Edit Video</h5>
                <button type="button" id="editVideoModalClose" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
         
            <div class="modal-body"  >
                <form name="editForm" [formGroup]="editvideoForm">
                    <div class="row">
                        <div class="col-md-5 text-center">

                            <div class='file customFile' >
                                <label for='fileEdit'>

                                    <ng-container *ngIf="videoUrl; else fileName" >
                                        <img   src="{{videoUrl}}" alt="" style="    max-width: 100%; max-height: 100%;">
                                    </ng-container>
                                    <ng-template #fileName>
                                        <div  class="p-3">
                                  
                                      
                                            <b>Your Video File</b><br>
                                            {{editVideoFileName}}
                                            
                                        </div>
                                    </ng-template>






                                  <!--<div *ngIf="editVideoFileName" class="p-3">
                                  
                                      
                                          <b>Your Video File</b><br>
                                          {{editVideoFileName}}
                                          
                                      </div>--> 
                                    
                                   
                                </label>
                                <input id='fileEdit' #fileInput     type='file' accept='video/mp4,video/MOV,video/AVI '   (change)="onFileChangeEdit($event)" />
                                <div *ngIf="editfileErrorMessage" class="invalid-tooltip d-block" style="right: 0;
                                font-size: 12px;
                                text-align: center;
                                margin-top: -52px;">
                                     Exceeds the maximum file upload limit of 200MB
                                </div>


                             
                            </div>
 
                            <a href="javascript:void(0)" #fileText  class="reUpload"  (click)="fileUploadFakeLink()"  >Re-Upload Video</a>
                        </div>
                        <div class="col-md-7">
                            <div class="form-group  position-relative">
                                <label for="Title" class="col-form-label"> Title
                                    <!-- <span class="required-fields">*</span> -->
                                </label>
                                <input type="text" class="form-control"  formControlName="heading" placeholder="Video Title">
                                <div *ngIf="editvideoForm.controls['heading'].invalid && (editvideoForm.controls['heading'].dirty || editvideoForm.controls['heading'].touched)" class="invalid-tooltip d-block" style="right: 0;left: inherit;">
                                    <div *ngIf="editvideoForm.controls['heading'].hasError('required')">Please Enter Title</div>
                                    <div *ngIf="editvideoForm.controls['heading'].hasError('maxlength')">Maximum 50 characters allowed</div>
                                    
                                </div>
                            </div>
                            <div class="form-group position-relative">
                                <label for="Title" class="col-form-label"> Category
                                    <!-- <span class="required-fields">*</span> -->
                                </label>
                                <select class="form-control noborder form-select" disabled>
                                    <option>{{group_name}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="form-group  position-relative">
                                <label for="Title" class="col-form-label"> Description
                                    <!-- <span class="required-fields">*</span> -->
                                </label>
                                <textarea rows="" cols="" class="form-control" formControlName="sub_heading">Description</textarea>
                                <div *ngIf="editvideoForm.controls['sub_heading'].invalid && (editvideoForm.controls['sub_heading'].dirty || editvideoForm.controls['sub_heading'].touched)" class="invalid-tooltip d-block" style="right: 0;left: inherit;">
                                    <div *ngIf="editvideoForm.controls['sub_heading'].hasError('required')">Please Enter Description</div>
                                    <div *ngIf="editvideoForm.controls['sub_heading'].hasError('maxlength')">Maximum 100 characters allowed</div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>

            <div class="modal-footer"  >
                <button type="button" class="btn btn-primary mt-3" [disabled]="!editvideoForm.valid"  (click)="editVideo(videoId)" >Done</button>
                  
              </div>

        </div>
        <div   *ngIf="progress!==null" style="margin: 0px auto;">
            <button style="display: none;" type="button" #editVideoModalClose  class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            
        <circle-progress
        [percent]="progress"
        [outerStrokeColor]="'#78C000'"
        [innerStrokeColor]="'#C7E596'"
        [animation]="true"
        [animationDuration]="0"
        [titleFontSize]="'28'"
        [titleFontWeight]="'900'"
        [unitsFontSize]="'28'"
        [unitsFontWeight]="'900'"
        [subtitle]="'Progress'"
        [subtitleFontSize]="'12'"
        [showBackground]="true"               
        [backgroundColor] = "'#ffffff'"
        [responsive]="false"
        [radius]="60"
        [space]="10"
      >
      </circle-progress>
    </div>
    </div>
</div>
<!-- ********** edit Video Modal end********* -->
 






<!-- /.modal -->
  